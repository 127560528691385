import { observer } from "mobx-react";
import { Route, RouteProps } from "react-router";
import { RoleType } from "../core/firebase/types/user.types";
import SessionStore from "../store/SessionStore";

type RoleBasedRouteType = RouteProps & {
  requiredRole: RoleType;
};

const RoleBasedRoute: React.FC<RoleBasedRouteType> = ({
  requiredRole,
  ...props
}) => {
  if (requiredRole === RoleType.ADMIN && !SessionStore.isAdmin) return null;
  if (requiredRole === RoleType.EMPLOYEE && !SessionStore.isEmployee)
    return null;
  return <Route {...props} />;
};

export default observer(RoleBasedRoute);
