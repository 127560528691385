import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonAlert,
} from "@ionic/react";
import { signOut } from "firebase/auth";
import { logOutOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import packageJson from "../../../package.json";
import { auth } from "../../core/firebase";
import SessionStore from "../../store/SessionStore";
import PasswordModal from "./PasswordModal";

const SettingsView: React.FC = () => {
  const [pwModalOpen, setPwModalOpen] = useState(false);
  const [presentAlert] = useIonAlert();

  const confirmLogout = () =>
    presentAlert({
      header: "Ausloggen bestätigen",
      message: `Bist du sicher, dass du dich ausloggen möchtest?`,
      buttons: [
        "Abbrechen",
        { text: "Ausloggen", handler: () => signOut(auth) },
      ],
    });

  useEffect(() => {
    if (SessionStore.requirePasswordReset != undefined)
      setPwModalOpen(SessionStore.requirePasswordReset);
  }, [SessionStore.requirePasswordReset]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Einstellungen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" className="ion-text-center">
        <IonList inset={!isPlatform("mobile")}>
          <IonItem detail onClick={() => setPwModalOpen(true)}>
            <IonLabel>Passwort ändern</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Version</IonLabel>
            <IonLabel class="ion-text-end">{packageJson.version}</IonLabel>
          </IonItem>
          <IonItem detail={false} button onClick={confirmLogout}>
            <IonIcon color="primary" slot="start" icon={logOutOutline} />
            <IonLabel color="primary">Ausloggen</IonLabel>
          </IonItem>
        </IonList>
        <PasswordModal
          isOpen={pwModalOpen}
          closeModal={() => setPwModalOpen(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default SettingsView;
