import React from "react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import { layers } from "ionicons/icons";
import { ItemFilter } from "./types";

type ItemViewFilterProps = {
  filter: ItemFilter;
  setFilter: (filter: ItemFilter) => void;
};

const ItemViewFilter: React.FC<ItemViewFilterProps> = ({
  filter,
  setFilter,
}) => {
  return (
    <>
      <IonButton id="filter-trigger">
        <IonIcon slot="icon-only" icon={layers} />
      </IonButton>
      <IonPopover dismissOnSelect trigger="filter-trigger">
        <IonContent className="ion-padding">
          <h4 style={{ margin: 0 }}>Sortieren nach</h4>
          <IonList>
            <IonRadioGroup value={filter}>
              <IonItem onClick={() => setFilter("vehicle")}>
                <IonLabel>Fahrzeug</IonLabel>
                <IonRadio slot="end" value="vehicle"></IonRadio>
              </IonItem>

              <IonItem onClick={() => setFilter("connection")}>
                <IonLabel>Verbindung</IonLabel>
                <IonRadio slot="end" value="connection"></IonRadio>
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default ItemViewFilter;
