import { IonList, isPlatform } from "@ionic/react";
import VehicleEntry from "../../../components/listItems/VehicleEntry";
import VehicleStore from "../../../store/VehicleStore";
import { observer } from "mobx-react";

const VehicleTableView = () => {
  const vehicles = VehicleStore.vehicles;

  if (!vehicles.length)
    return (
      <p className="ion-padding">
        <i>Keine Fahrzeuge vorhanden</i>
      </p>
    );

  return (
    <IonList inset={!isPlatform("mobile")}>
      {vehicles?.map((vehicle, idx) => {
        return <VehicleEntry vehicle={vehicle} detail key={idx} />;
      })}
    </IonList>
  );
};

export default observer(VehicleTableView);
