import { makeAutoObservable, runInAction } from "mobx";
import { ItemType } from "../core/firebase/types/item.types";
import {
  subscribeItemsByCompany,
  subscribeItemsByVehicle,
} from "../core/firebase/collections/itemCollection";
import { Unsubscribe } from "firebase/firestore";
import groupBy from "lodash/groupBy";
import ScanStore from "./ScanStore";
import VehicleStore from "./VehicleStore";

class ItemStore {
  items: ItemType[] = [];
  unsubscribeItems?: Unsubscribe;

  constructor() {
    makeAutoObservable(this);
  }

  get itemCount() {
    return this.items.length;
  }

  get itemIds() {
    return this.items.map((item) => item.id);
  }

  get connectedItems() {
    return this.items.filter((item) => ScanStore.foundIds.includes(item.id));
  }

  get unconnectedItems() {
    return this.items.filter((item) => !ScanStore.foundIds.includes(item.id));
  }

  get unknownItemIds() {
    return ScanStore.foundIds.filter(
      (itemId) => !this.itemIds.includes(itemId)
    );
  }

  get itemsGroupedByVehicleId() {
    return groupBy(this.items, "vehicleId") as Record<string, ItemType[]>;
  }

  get currentVehicleFoundItems() {
    if (!VehicleStore.currentVehicle) return [];
    const items = this.itemsGroupedByVehicleId[VehicleStore.currentVehicle.id];
    if (!items) return [];
    return items.filter((item) =>
      ScanStore.currentVehicleLatestScan?.foundIds.includes(item.id)
    );
  }

  get currentVehicleNotFoundItems() {
    if (!VehicleStore.currentVehicle) return [];
    const items = this.itemsGroupedByVehicleId[VehicleStore.currentVehicle.id];
    if (!items) return [];
    return items.filter(
      (item) => !ScanStore.currentVehicleLatestScan?.foundIds.includes(item.id)
    );
  }

  get currentVehicleItems() {
    if (!VehicleStore.currentVehicle) return [];
    const items = this.itemsGroupedByVehicleId[VehicleStore.currentVehicle.id];
    if (!items) return [];
    return items;
  }

  isConnected = (itemId: string) => {
    return this.connectedItems.some((item) => item.id === itemId);
  };

  onNewItemSnapshot = (items: ItemType[]) => {
    runInAction(() => {
      this.items = items;
    });
  };

  setUnsubscribe = (unsubscribe?: Unsubscribe) => {
    this.unsubscribeItems = unsubscribe;
  };

  subscribeVehicleItems(vehicleIds?: string[]) {
    if (this.unsubscribeItems) this.unsubscribeItems();
    this.resetStore();

    if (!vehicleIds || !vehicleIds.length) return;
    const unsubscribe = subscribeItemsByVehicle(
      this.onNewItemSnapshot,
      vehicleIds
    );
    runInAction(() => {
      this.setUnsubscribe(unsubscribe);
    });
  }

  subscribeCompanyItems() {
    const unsubscribe = subscribeItemsByCompany(this.onNewItemSnapshot);
    this.setUnsubscribe(unsubscribe);
  }

  stopListeningForItems() {
    this.unsubscribeItems && this.unsubscribeItems();
    this.resetStore();
  }

  resetStore() {
    runInAction(() => {
      this.items = [];
      this.unsubscribeItems = undefined;
    });
  }
}

export default new ItemStore();
