import { IonProgressBar } from "@ionic/react";

type ItemProgressProps = {
  itemCount: number;
  foundItems: number;
};

const ItemProgress: React.FC<ItemProgressProps> = ({
  itemCount,
  foundItems,
}) => {
  const itemAvaliability = foundItems / itemCount;
  let progressColor;
  if (itemCount <= 0) progressColor = "warning";
  else if (itemAvaliability >= 1) progressColor = "success";
  else if (itemAvaliability >= 0.8) progressColor = "warning";
  else progressColor = "danger";

  return (
    <IonProgressBar
      className="item-progress-bar"
      color={progressColor}
      value={itemAvaliability}
    />
  );
};

export default ItemProgress;
