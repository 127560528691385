import {
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonToolbar,
} from "@ionic/react";
import { observer } from "mobx-react";
import { useState } from "react";
import LoaderOverlay from "../../../components/LoaderOverlay";
import Modal from "../../../components/modal/Modal";
import UserStore from "../../../store/UserStore";
import VehicleStore from "../../../store/VehicleStore";

type ModalAssignEmployeesProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const ModalAssignEmployees: React.FC<ModalAssignEmployeesProps> = ({
  isOpen,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(
    VehicleStore.currentVehicle?.responsibleUsers || []
  );

  const handleSelectionChange = (checked: boolean, employeeId: string) => {
    console.log(checked);

    if (checked) return setSelectedUsers([...selectedUsers, employeeId]);
    const usersWithRemovedId = selectedUsers.filter(
      (userId) => userId !== employeeId
    );
    setSelectedUsers(usersWithRemovedId);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await VehicleStore.assignEmployeesToVehicle(selectedUsers);
    setIsLoading(false);
    closeModal();
  };

  const submitButton = (
    <IonToolbar color="light">
      <IonButton onClick={handleSubmit} slot="end">
        <IonLabel>Speichern</IonLabel>
      </IonButton>
    </IonToolbar>
  );

  return (
    <Modal
      title="Mitarbeiter zuweisen"
      isOpen={isOpen}
      closeModal={closeModal}
      footerElements={submitButton}
    >
      <IonList>
        {UserStore.users.map((employee) => (
          <IonItem key={employee.id}>
            <IonCheckbox
              checked={selectedUsers.includes(employee.id)}
              onIonChange={(e) =>
                handleSelectionChange(e.detail.checked, employee.id)
              }
              slot="end"
            />
            <IonLabel>
              {employee.firstName} {employee.lastName}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
      <LoaderOverlay
        isOpen={isLoading}
        message="Mitarbeiter werden zugewiesen.."
      />
    </Modal>
  );
};

export default observer(ModalAssignEmployees);
