import { IonButton, IonButtons, IonIcon } from "@ionic/react";
import { pencil } from "ionicons/icons";
import { observer } from "mobx-react";
import { useState } from "react";
import { UserType } from "../../../core/firebase/types/user.types";
import ModalAddUser from "../AddUser/ModalAddUser";

const UserEdit: React.FC<{
  user: UserType;
  onSuccess: () => void;
}> = ({ user, onSuccess }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <IonButtons slot="end">
      <IonButton onClick={() => setEditModalOpen(true)}>
        <IonIcon icon={pencil} />
      </IonButton>
      <ModalAddUser
        isOpen={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
        user={user}
        onSuccess={onSuccess}
      />
    </IonButtons>
  );
};

export default observer(UserEdit);
