import { Unsubscribe } from "firebase/auth";
import { action, makeObservable, observable } from "mobx";
import { subscribeUsersByCompany } from "../core/firebase/collections/userCollection";
import { UserType } from "../core/firebase/types/user.types";
import SessionStore from "./SessionStore";

class UserStore {
  users: UserType[] = [];
  private unsubscribeUsers?: Unsubscribe;

  constructor() {
    makeObservable(this, {
      users: observable,
      getUser: action,
      onNewUserSnapshot: action,
      setUnsubscribe: action,
      subscribeUsers: action,
      stopListeningForUsers: action,
      resetStore: action,
    });
  }

  getUser(userId?: string) {
    return this.users.find((user) => user.id === userId);
  }

  onNewUserSnapshot = (users: UserType[]) => {
    this.users = users;
  };

  setUnsubscribe = (unsubscribe?: Unsubscribe) => {
    this.unsubscribeUsers = unsubscribe;
  };

  subscribeUsers() {
    const user = SessionStore.user;
    if (!user) return;

    let unsubscribe;
    unsubscribe = subscribeUsersByCompany(
      user.companyId,
      this.onNewUserSnapshot
    );

    this.setUnsubscribe(unsubscribe);
  }

  stopListeningForUsers = () => {
    this.unsubscribeUsers && this.unsubscribeUsers();
    this.setUnsubscribe();
  };

  resetStore() {
    this.users = [];
    this.unsubscribeUsers = undefined;
  }
}

export default new UserStore();
