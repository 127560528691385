import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../core/firebase";
import { observer } from "mobx-react";
import SessionStore from "../store/SessionStore";
import LoaderOverlay from "../components/LoaderOverlay";
import { isPlatform } from "@ionic/react";
import Menu from "../components/layout/menu/Menu";
import TabBar from "../components/layout/TabBar";

const TracktopusRouter: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);

  if (loading || SessionStore.loading)
    return <LoaderOverlay isOpen={loading || SessionStore.loading} />;

  if (!isPlatform("mobile")) {
    return <Menu />;
  }

  return <TabBar />;
};

export default observer(TracktopusRouter);
