import React, { useEffect, useState } from "react";
import {
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
} from "@ionic/react";
import { observer } from "mobx-react";
import VehicleStore from "../../../store/VehicleStore";
// import { ItemListType } from "../../../core/firebase/types/ItemList.types";
// import ItemListStore from "../../../store/ItemListStore";
import UserStore from "../../../store/UserStore";
import VehicleEntry from "../../../components/listItems/VehicleEntry";
import { useParams, withRouter } from "react-router";
import ModalAssignEmployees from "./ModalAssignEmployees";
import LoaderOverlay from "../../../components/LoaderOverlay";

const VehicleDetailView: React.FC = () => {
  const { vehicleId } = useParams<{ vehicleId: string }>();

  const [employeeModalOpen, setEmployeeModalOpen] = useState(false);
  const vehicle = VehicleStore.currentVehicle;

  useEffect(() => {
    VehicleStore.setCurrentVehicleId(vehicleId);
  }, []);

  // const itemLists: ItemListType[] = ItemListStore.itemLists;

  if (!vehicle)
    return <LoaderOverlay message="Fahrzeug wird geladen.." isOpen={true} />;

  // const assignedItemLists = itemLists.filter((list) =>
  //   list.vehicleIds.includes(vehicle.id)
  // );

  const responsibleUsers = UserStore.users.filter((user) =>
    vehicle.responsibleUsers?.includes(user.id)
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text="Zurück" defaultHref="/Fahrzeuge" />
          </IonButtons>
          <IonTitle>Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <VehicleEntry vehicle={vehicle} />
        <IonList>
          <IonItem color="light"></IonItem>
          <IonItem button detail onClick={() => setEmployeeModalOpen(true)}>
            <IonLabel>Mitarbeiter</IonLabel>
            <IonLabel slot="end" class="ion-text-end">
              {responsibleUsers.length}
            </IonLabel>
          </IonItem>
          <IonItem disabled lines="full">
            <IonLabel>Standort</IonLabel>
            <IonLabel class="ion-text-end">
              {vehicle.location || "Coming Soon..."}
            </IonLabel>
          </IonItem>
          <IonItem color="light"></IonItem>
          <IonItem disabled>
            <IonLabel>Werkzeuglisten</IonLabel>
            <IonLabel class="ion-text-end">{"Coming Soon..."}</IonLabel>
          </IonItem>
          <IonItem
            routerLink={`/Fahrzeuge/${vehicle.id}/Werkzeugbestand`}
            detail
          >
            <IonLabel>Werkzeugbestand</IonLabel>
            <IonLabel class="ion-text-end"></IonLabel>
          </IonItem>
        </IonList>
        <ModalAssignEmployees
          isOpen={employeeModalOpen}
          closeModal={() => setEmployeeModalOpen(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default withRouter(observer(VehicleDetailView));
