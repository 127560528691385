import { IonIcon, IonItem, IonLabel, IonMenuToggle } from "@ionic/react";
import { useLocation } from "react-router";
import { AppPage } from "../AppPages";

interface MenuItemProps {
  appPage: AppPage;
}

export const MenuItem: React.FC<MenuItemProps> = ({ appPage }) => {
  const location = useLocation();
  return (
    <IonMenuToggle autoHide={false}>
      <IonItem
        style={{ fontWeight: "bolder" }}
        color={location.pathname.includes(appPage.url) ? "primary" : undefined}
        routerLink={appPage.url}
        routerDirection="none"
        lines="none"
      >
        <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
        <IonLabel>{appPage.title}</IonLabel>
      </IonItem>
    </IonMenuToggle>
  );
};
