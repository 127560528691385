import { IonItem, IonLabel } from "@ionic/react";
import { observer } from "mobx-react";
import SessionStore from "../../store/SessionStore";

const UserLoginInfo: React.FC = () => {
  const user = SessionStore.user;
  if (!user) return null;
  return (
    <IonItem color="light" lines="none">
      <IonLabel style={{ fontSize: "19px", fontWeight: "bolder" }}>
        {`${user.firstName} ${user.lastName}`}
        <p style={{ fontSize: "12px" }}>
          <i>{user.roles.join(", ")}</i>
        </p>
      </IonLabel>
    </IonItem>
  );
};

export default observer(UserLoginInfo);
