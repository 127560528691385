import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { observer } from "mobx-react";
import { getPages } from "./AppPages";
import Routes from "../../router/Routes";
import SessionStore from "../../store/SessionStore";

const TabBar: React.FC = () => {
  return (
    <IonTabs>
      <IonRouterOutlet id="main">
        <Routes />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" hidden={!SessionStore.user}>
        {getPages().map((appPage) => (
          <IonTabButton
            key={appPage.title}
            tab={appPage.title}
            href={appPage.url}
          >
            <IonIcon ios={appPage.iosIcon} md={appPage.mdIcon} />
            <IonLabel>{appPage.title}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};

export default observer(TabBar);
