export enum RoleType {
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
}
export const getNameByRole = (role: RoleType) => {
  if (role == RoleType.EMPLOYEE) return "Mitarbeiter";
  return "Admin";
};

export interface UserType {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  roles: RoleType[];
  companyId: string;
  status: "planned" | "operating" | "idle" | "unknown";
  hasLicense?: boolean;
}

export type UserCredentials = {
  userDoc: Omit<UserType, "id">;
  password: string;
};
