import {
  IonButton,
  IonButtons,
  IonIcon,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { trash } from "ionicons/icons";
import { deleteUser } from "../../../core/firebase/functionCallables/userCallables";
import { UserType } from "../../../core/firebase/types/user.types";
import SessionStore from "../../../store/SessionStore";
const UserDelete: React.FC<{
  user: UserType;
  errorToast: (text: string) => void;
  setShowLoader: (showing: boolean) => void;
}> = ({ user, errorToast, setShowLoader }) => {
  const [presentAlert] = useIonAlert();
  const router = useIonRouter();

  const showRemoveUserAlert = () => {
    presentAlert({
      header: "Mitarbeiter entfernen",
      message: `Möchtest du ${user.email} wirklich entfernen?`,
      buttons: [
        "Abbrechen",
        { text: "Ja", handler: () => removeUser(user.id) },
      ],
    });
  };

  const removeUser = async (userId: string) => {
    setShowLoader(true);
    const status = await deleteUser(userId);
    setShowLoader(false);
    if (status != "deleted") {
      errorToast("Entfernen");
    } else {
      router.goBack();
    }
  };

  return (
    <IonButtons slot="end">
      <IonButton
        disabled={SessionStore.userId == user.id}
        onClick={showRemoveUserAlert}
      >
        <IonIcon icon={trash} />
      </IonButton>
    </IonButtons>
  );
};

export default UserDelete;
