import { IonList, IonListHeader, isPlatform } from "@ionic/react";
import { observer } from "mobx-react";
import ItemStore from "../../store/ItemStore";
import VehicleItemListEntry from "../../components/listItems/ItemListEntry";
import { ScanStatus } from "../../core/firebase/types/scan.types";

const ListItemsByConnection: React.FC = () => {
  return (
    <>
      <IonList inset={!isPlatform("mobile")}>
        <IonListHeader>Nicht erkannte Werkzeuge</IonListHeader>
        {ItemStore.unconnectedItems.map((item) => (
          <VehicleItemListEntry
            key={item.id}
            item={item}
            status={ScanStatus.UNCONNECTED}
          />
        ))}
        <IonListHeader>Erkannte Werkzeuge</IonListHeader>
        {ItemStore.connectedItems.map((item) => (
          <VehicleItemListEntry
            key={item.id}
            item={item}
            status={ScanStatus.CONNECTED}
          />
        ))}
      </IonList>
    </>
  );
};

export default observer(ListItemsByConnection);
