import { doc, getDoc, query, where } from "firebase/firestore";
import SessionStore from "../../../store/SessionStore";
import { CollectionName } from "../types/collections.types";
import { ItemType } from "../types/item.types";
import { createCollection, subscribeCollection } from "./collectionService";

const itemCollection = createCollection<ItemType>(CollectionName.ITEMS);

export const subscribeItemsByCompany = (
  onNewSnapshot: (items: ItemType[]) => void
) => {
  console.log("fetching all items for company");
  const itemByCompanyQuery = query(
    itemCollection,
    where("companyId", "==", SessionStore.companyId)
  );
  return subscribeCollection<ItemType>(itemByCompanyQuery, onNewSnapshot);
};

export const subscribeItemsByVehicle = (
  onNewSnapshot: (items: ItemType[]) => void,
  vehicleIds?: string[]
) => {
  console.log("fetching all items for assigned vehicles");
  if (!vehicleIds) return;
  const itemByVehicleQuery = query(
    itemCollection,
    where("companyId", "==", SessionStore.companyId),
    where("vehicleId", "in", vehicleIds)
  );
  return subscribeCollection<ItemType>(itemByVehicleQuery, onNewSnapshot);
};

export const getUnknownItemDocs = async (unknownItemIds: string[]) => {
  if (!unknownItemIds.length) return;
  console.log("Unknown items found. Fetching item data");
  console.log("unknownItemIds", unknownItemIds);
  const unknownItemsDocRefs = unknownItemIds.map(async (unknownItemId) => {
    const itemDoc = doc(itemCollection, unknownItemId);
    return await getDoc(itemDoc);
  });
  const unknownItems = await Promise.all(unknownItemsDocRefs);
  return unknownItems.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    } as ItemType;
  });
};

export default itemCollection;
