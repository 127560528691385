import { IonBadge, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { person } from "ionicons/icons";
import { observer } from "mobx-react";
import { RoleType, UserType } from "../../core/firebase/types/user.types";

interface UserEntryProps {
  user: UserType;
  detail?: boolean;
}

const UserEntry: React.FC<UserEntryProps> = ({ user, detail = false }) => {
  return (
    <IonItem
      key={user.id}
      detail={detail}
      routerLink={detail ? `/Mitarbeiter/${user.id}` : undefined}
    >
      <IonIcon slot="start" icon={person} />
      <IonLabel style={{ fontWeight: "bolder" }}>
        {user.firstName} {user.lastName}
        <p>{user.email}</p>
      </IonLabel>
      {user.roles.includes(RoleType.ADMIN) && (
        <IonBadge slot="end">Admin</IonBadge>
      )}
    </IonItem>
  );
};

export default observer(UserEntry);
