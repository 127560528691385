import { IonItem, IonLabel } from "@ionic/react";
import { ItemType } from "../../core/firebase/types/item.types";
import { ScanStatus } from "../../core/firebase/types/scan.types";

export type VehicleItemListEntryProps = {
  item: ItemType;
  status: ScanStatus;
};

const VehicleItemListEntry: React.FC<VehicleItemListEntryProps> = ({
  item,
  status,
}) => {
  return (
    <IonItem>
      <span slot="start" className={`dot dot-${status} ion-margin-end`} />
      <IonLabel style={{ fontWeight: "bolder" }}>
        {item.name}
        <p>{item.toolType}</p>
      </IonLabel>
    </IonItem>
  );
};

export default VehicleItemListEntry;
