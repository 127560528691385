import { httpsCallable } from "firebase/functions";
import { functions } from "..";
import { UserCredentials, UserType } from "../types/user.types";

export type UserCreationStatus =
  | "created"
  | "auth/email-already-exists"
  | "error";

export type UserDeletionStatus = "deleted" | "error";
export type UserUpdatingStatus = "updated" | "error";
export type ClaimsAssignmentStatus = "claimsreassigned" | "error";
export type ClaimsPwResetRemoveStatus = "pwresetclaimremoved" | "error";

export const createUser = async (
  UserCredentials: UserCredentials
): Promise<UserCreationStatus> => {
  const createUser = httpsCallable<UserCredentials, { status: number }>(
    functions,
    "usercontroller-createuser"
  );

  try {
    await createUser(UserCredentials);
    return "created";
  } catch (err) {
    const { _, details } = JSON.parse(JSON.stringify(err));
    return details.code;
  }
};

export const deleteUser = async (
  userId: string
): Promise<UserDeletionStatus> => {
  const deleteUser = httpsCallable<{ userId: string }, { status: number }>(
    functions,
    "usercontroller-deleteuser"
  );
  try {
    await deleteUser({ userId });
    return "deleted";
  } catch (err) {
    console.error(err);
    const { _, details } = JSON.parse(JSON.stringify(err));
    return details.code;
  }
};

export const reassignClaims = async (
  user: UserType
): Promise<ClaimsAssignmentStatus> => {
  const reassignclaims = httpsCallable<UserType, { status: number }>(
    functions,
    "usercontroller-reassignclaims"
  );
  try {
    await reassignclaims(user);
    return "claimsreassigned";
  } catch (err) {
    console.error(err);
    const { _, details } = JSON.parse(JSON.stringify(err));
    return details.code;
  }
};

export const updateUser = async (
  user: UserType
): Promise<UserUpdatingStatus> => {
  const updateuser = httpsCallable<UserType, { status: number }>(
    functions,
    "usercontroller-updateuser"
  );
  try {
    await updateuser(user);
    return "updated";
  } catch (err) {
    console.error(err);
    const { _, details } = JSON.parse(JSON.stringify(err));
    return details.code;
  }
};

export const removePwResetClaim = async (
  userId: string
): Promise<ClaimsPwResetRemoveStatus> => {
  const removeuserpasswordresetclaim = httpsCallable<
    { userId: string },
    { status: number }
  >(functions, "usercontroller-removeuserpasswordresetclaim");
  try {
    await removeuserpasswordresetclaim({ userId });
    return "pwresetclaimremoved";
  } catch (err) {
    console.error(err);
    const { _, details } = JSON.parse(JSON.stringify(err));
    return details.code;
  }
};
