import {
  addDoc,
  doc,
  documentId,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import SessionStore from "../../../store/SessionStore";
import { CollectionName } from "../types/collections.types";
import { Scan } from "../types/scan.types";
import { createCollection, subscribeCollection } from "./collectionService";

export const scanCollection = createCollection<Scan>(
  CollectionName.SCAN_RESULTS
);

export const subscribeScansByCompany = (
  onNewSnapshot: (scanResults: Scan[]) => void
) => {
  console.log("Subscribing latest scan result from company");
  const scanQuery = query(
    scanCollection,
    where("latest", "==", true),
    where("companyId", "==", SessionStore.companyId),
    orderBy("timestamp", "desc")
  );
  return subscribeCollection<Scan>(scanQuery, onNewSnapshot);
};

export const subscribeScansByVehicleIds = (
  vehicleIds: string[],
  onNewSnapshot: (scanResults: Scan[]) => void
) => {
  console.log("Subscribing scan results from assigned vehicles");
  const scanQuery = query(
    scanCollection,
    where("companyId", "==", SessionStore.companyId),
    where("latest", "==", true),
    where("vehicleId", "in", vehicleIds),
    orderBy("timestamp", "desc")
  );
  return subscribeCollection<Scan>(scanQuery, onNewSnapshot);
};

const unflagLatestScanResult = async (docId: string, vehicleId: string) => {
  const scanQuery = query(
    scanCollection,
    where(documentId(), "!=", docId),
    where("companyId", "==", SessionStore.companyId),
    where("latest", "==", true),
    where("vehicleId", "==", vehicleId)
  );
  const latestDocs = await getDocs(scanQuery);

  const updatePromises = latestDocs.docs.map((scan) =>
    updateScan(scan.id, { latest: false })
  );

  return await Promise.all(updatePromises);
};

const updateScan = async (docId: string, partialScan: Partial<Scan>) => {
  return updateDoc(doc(scanCollection, docId), partialScan);
};

export const addScan = async (newScan: Scan) => {
  const newScanDoc = await addDoc(scanCollection, newScan);

  return unflagLatestScanResult(newScanDoc.id, newScan.vehicleId);
};

export default scanCollection;
