import { observer } from "mobx-react";
import { Scan } from "../../core/firebase/types/scan.types";
import { toDateTimeString } from "../../core/utils/dateTimeUtils";
import UserStore from "../../store/UserStore";

type ScanInfoProps = {
  scan?: Scan;
  withPerson?: boolean;
};

const ScanInfo: React.FC<ScanInfoProps> = ({ scan, withPerson = false }) => {
  const scanUser = UserStore.getUser(scan?.userId);
  const scanTime = toDateTimeString(scan?.timestamp);

  const personName = withPerson
    ? ` (${scanUser?.firstName} ${scanUser?.lastName})`
    : "";

  if (!scan)
    return (
      <p>
        <b>
          <i>Kein Scan vorhanden</i>
        </b>
      </p>
    );

  return (
    <p>
      <b>{`${scanTime} ${personName}`}</b>
    </p>
  );
};

export default observer(ScanInfo);
