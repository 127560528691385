import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoaderOverlay from "../../../components/LoaderOverlay";
import Modal from "../../../components/modal/Modal";
import {
  createUser,
  updateUser,
} from "../../../core/firebase/functionCallables/userCallables";
import { RoleType, UserType } from "../../../core/firebase/types/user.types";
import SessionStore from "../../../store/SessionStore";
import {
  AddUserFormType,
  mailValidation,
  nameValidation,
  passwordValidation,
} from "./InputValidators";

type ModalAddUserProps = {
  closeModal: () => void;
  isOpen: boolean;
  user?: UserType;
  onSuccess?: () => void;
};

const ModalAddUser: React.FC<ModalAddUserProps> = ({
  closeModal,
  isOpen,
  user,
  onSuccess,
}) => {
  const companyId = SessionStore.user?.companyId;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddUserFormType>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (user) {
      reset(user);
      setRole(user?.roles[0]);
    }
  }, [user]);

  const [role, setRole] = useState<RoleType>(RoleType.EMPLOYEE);

  const [isLoading, setIsLoading] = useState(false);
  const [presentToast] = useIonToast();

  const submit = async (data: AddUserFormType) => {
    const { firstName, lastName } = data;
    setIsLoading(true);
    let status;
    if (user) {
      status = await update(role, firstName, lastName);
    } else {
      status = await create(data);
    }
    setIsLoading(false);

    if (status == "created" || status == "updated") {
      onSuccess && onSuccess();
      setRole(RoleType.EMPLOYEE);
      modalDidDismiss();
      return;
    }
    presentErrorByStatus(status);
  };

  const presentErrorByStatus = (status?: string) => {
    console.log(status);
    let error =
      "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.";
    if (status == "auth/email-already-exists") {
      error =
        "Zu dieser E-Mail liegt bereits ein Account vor, bitte nutzen Sie eine verfügbare E-Mail.";
    }
    presentToast({
      message: error,
      duration: 4500,
      color: "danger",
    });
  };

  const update = async (
    role: RoleType,
    firstName: string,
    lastName: string
  ) => {
    if (!user) return;
    return await updateUser({
      ...user,
      firstName,
      lastName,
      roles: [role],
    });
  };

  const create = async (data: AddUserFormType) => {
    if (!companyId) return;
    const { firstName, lastName, password, email } = data;
    const newUser: Omit<UserType, "id"> = {
      firstName,
      lastName,
      email,
      roles: [role],
      status: "idle",
      companyId,
    };
    return await createUser({ userDoc: newUser, password: password });
  };

  const modalDidDismiss = () => {
    reset();
    closeModal();
  };

  const submitButton = (
    <IonToolbar color="light">
      <IonButton
        id="submitUserButton"
        onClick={handleSubmit(submit)}
        slot="end"
      >
        <IonLabel>{user ? "Aktualisieren" : "Hinzufügen"}</IonLabel>
      </IonButton>
    </IonToolbar>
  );

  return (
    <Modal
      title={user ? "Mitarbeiter bearbeiten" : "Neuer Mitarbeiter"}
      isOpen={isOpen}
      closeModal={modalDidDismiss}
      footerElements={submitButton}
    >
      <IonList>
        <IonItem>
          <IonLabel position="stacked">Vorname</IonLabel>
          <IonInput {...register("firstName", nameValidation)} />
          {errors.firstName && (
            <IonNote slot="helper">Bitte gib einen Vornamen an</IonNote>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Nachname</IonLabel>
          <IonInput {...register("lastName", nameValidation)} />
          {errors.lastName && (
            <IonNote slot="helper">Bitte gib einen Nachnamen an</IonNote>
          )}
        </IonItem>
        <IonItem disabled={user != undefined}>
          <IonLabel position="stacked">E-Mail</IonLabel>
          <IonInput {...register("email", mailValidation)} />
          {errors.email && (
            <IonNote slot="helper">
              Bitte gib eine gültige E-Mail Adresse an
            </IonNote>
          )}
        </IonItem>
        {!user && (
          <IonItem>
            <IonLabel position="stacked">Initiales Passwort</IonLabel>
            <IonInput {...register("password", passwordValidation)} />
            {errors.password && (
              <IonNote slot="helper">
                Bitte gib eine Passwort mit mindestens{" "}
                {passwordValidation.minLength} Zeichen an
              </IonNote>
            )}
          </IonItem>
        )}
        <IonItem>
          <IonLabel position="stacked">Rolle</IonLabel>
          <IonSelect
            interface="popover"
            value={role}
            placeholder="Rolle zuweisen"
            onIonChange={(value) => setRole(value.detail.value)}
            disabled={user && SessionStore.userId == user.id}
          >
            <IonSelectOption value={RoleType.ADMIN}>Admin</IonSelectOption>
            <IonSelectOption value={RoleType.EMPLOYEE}>
              Mitarbeiter
            </IonSelectOption>
          </IonSelect>
        </IonItem>
        <LoaderOverlay
          isOpen={isLoading}
          message={`Mitarbeiter wird ${
            user ? "aktualisiert" : "hinzugefügt"
          }..`}
        />
      </IonList>
    </Modal>
  );
};
export default observer(ModalAddUser);
