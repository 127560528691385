import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { car } from "ionicons/icons";
import { VehicleType } from "../../../core/firebase/types/vehicle.types";
import ItemProgress from "../../../components/ItemProgress";
import ItemStore from "../../../store/ItemStore";
import { observer } from "mobx-react";
import ScanInfo from "../../../components/texts/ScanInfo";
import ScanStore from "../../../store/ScanStore";

type LastScanInfoItemProps = {
  vehicle?: VehicleType;
};

const LastScanInfoItem: React.FC<LastScanInfoItemProps> = ({ vehicle }) => {
  const scan = ScanStore.currentVehicleLatestScan;
  const licensePlate = vehicle?.licensePlate
    ? ` (${vehicle?.licensePlate})`
    : "";

  return (
    <div id="vehicle-tool-container" color="light">
      <IonItem lines="none">
        <IonIcon slot="start" icon={car} />
        <IonLabel>
          <b>{vehicle?.name}</b>
          {licensePlate}
          <ScanInfo scan={scan} withPerson />
        </IonLabel>
      </IonItem>
      <ItemProgress
        itemCount={ItemStore.currentVehicleItems.length}
        foundItems={ItemStore.currentVehicleFoundItems.length}
      />
    </div>
  );
};

export default observer(LastScanInfoItem);
