import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  isPlatform,
} from "@ionic/react";
import { locateOutline } from "ionicons/icons";
import { useState } from "react";
import { scan } from "../../../core/ble";
import ScanStore from "../../../store/ScanStore";
import ItemScanLoader from "../../itemOverview/ItemScanLoader";

type ItemScanFabButtonProps = {
  itemCount: number;
};

const ItemScanFabButton: React.FC<ItemScanFabButtonProps> = ({ itemCount }) => {
  const [foundItems, setFoundItems] = useState(0);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);

  if (!isPlatform("mobile")) return null;

  const onFabButtonClick = () => {
    setIsLoaderOpen(true);
    scan(
      itemCount,
      () => setFoundItems(foundItems + 1),
      async (scannedDevices) => {
        await ScanStore.addScan(scannedDevices);
        setIsLoaderOpen(false);
        setFoundItems(0);
      }
    );
  };

  return (
    <>
      <IonFab vertical="bottom" horizontal="center" slot="fixed">
        <IonFabButton color="primary" onClick={onFabButtonClick}>
          <IonLabel>
            <IonIcon icon={locateOutline} />
            <p style={{ color: "#333333" }}>SCAN</p>
          </IonLabel>
        </IonFabButton>
      </IonFab>
      <ItemScanLoader
        itemCount={itemCount}
        foundItems={foundItems}
        isOpen={isLoaderOpen}
      />
    </>
  );
};

export default ItemScanFabButton;
