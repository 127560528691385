import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { car } from "ionicons/icons";
import { Scan } from "../../core/firebase/types/scan.types";
import { VehicleType } from "../../core/firebase/types/vehicle.types";

import ItemProgress from "../../components/ItemProgress";
import { ItemType } from "../../core/firebase/types/item.types";
import VehicleStore from "../../store/VehicleStore";
import ScanInfo from "../texts/ScanInfo";

type VehicleToolEntryProps = {
  vehicle: VehicleType;
  scan?: Scan;
  totalItems: ItemType[];
};

const VehicleToolEntry: React.FC<VehicleToolEntryProps> = ({
  vehicle,
  scan,
  totalItems,
}) => {
  const foundVehicleItemsLength = totalItems.filter((item) =>
    scan?.foundIds.includes(item.id)
  ).length;
  const state = `${scan ? foundVehicleItemsLength : "-"} / ${
    totalItems.length || 0
  }`;

  return (
    <div id="vehicle-tool-container" color="light">
      <IonItem
        detail
        lines="none"
        routerLink={`/Werkzeuguebersicht/${vehicle.id}/Werkzeugbestand`}
        onClick={() => VehicleStore.setCurrentVehicleId(vehicle.id)}
      >
        <IonIcon slot="start" icon={car} />
        <IonLabel style={{ fontWeight: "bolder" }}>
          {vehicle.name}
          <ScanInfo scan={scan} />
        </IonLabel>
        <IonLabel slot="end">{state}</IonLabel>
      </IonItem>
      <ItemProgress
        itemCount={totalItems.length}
        foundItems={foundVehicleItemsLength}
      />
    </div>
  );
};

export default VehicleToolEntry;
