import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router";
import VehicleItemListEntry from "../../../components/listItems/ItemListEntry";
import LoaderOverlay from "../../../components/LoaderOverlay";
import { ScanStatus } from "../../../core/firebase/types/scan.types";
import ItemStore from "../../../store/ItemStore";
import VehicleStore from "../../../store/VehicleStore";
import ItemScanFabButton from "./ItemScanFabButton";
import LastScanInfoItem from "./LastScanInfoItem";

const VehicleItemView: React.FC = () => {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const vehicle = VehicleStore.currentVehicle;

  useEffect(() => {
    if (vehicleId && !VehicleStore.currentVehicle)
      VehicleStore.setCurrentVehicleId(vehicleId);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton
              text="Zurück"
              defaultHref={`/Fahrzeuge/${vehicleId}`}
            />
          </IonButtons>
          <IonTitle>Werkzeugbestand</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <LastScanInfoItem vehicle={vehicle} />
        <IonItem lines="none" color="light" className="ion-text-center">
          <IonLabel>
            <b>
              {ItemStore.currentVehicleFoundItems.length} /{" "}
              {ItemStore.currentVehicleItems.length}
            </b>
          </IonLabel>
        </IonItem>
        <IonList inset={!isPlatform("mobile")}>
          {ItemStore.currentVehicleNotFoundItems.map((item) => (
            <VehicleItemListEntry
              key={item.id}
              item={item}
              status={ScanStatus.UNCONNECTED}
            />
          ))}
          {ItemStore.currentVehicleFoundItems.map((item) => (
            <VehicleItemListEntry
              key={item.id}
              item={item}
              status={ScanStatus.CONNECTED}
            />
          ))}
        </IonList>
        <IonItem color="light" lines="none" />
        <IonItem color="light" lines="none" />
        <ItemScanFabButton itemCount={ItemStore.currentVehicleItems.length} />
        {!vehicle && (
          <LoaderOverlay message="Fahrzeug wird geladen.." isOpen={true} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default observer(VehicleItemView);
