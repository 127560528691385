import { Redirect, Route } from "react-router";
import { RoleType } from "../core/firebase/types/user.types";
import SettingsView from "../pages/settingsView/SettingsView";
import VehicleDetailView from "../pages/vehicleView/vehicleDetail/VehicleDetailView";
import VehicleView from "../pages/vehicleView/VehicleView";
import RoleBasedRoute from "./RoleBasedRoute";
import UsersView from "../pages/usersView/UsersView";
import VehicleItemView from "../pages/vehicleView/vehicleItem/VehicleItemView";
import ItemOverview from "../pages/itemOverview/ItemOverview";
import SessionStore from "../store/SessionStore";
import UserDetailView from "../pages/usersView/userDetail/UserDetailView";
import LoginScreen from "../pages/login/LoginScreen";
import { observer } from "mobx-react-lite";

export const PublicRoutes = (
  <>
    <Route key="loginScreen" path="/Anmeldung" component={LoginScreen} exact />
    <Redirect key="loginRedirect" to="/Anmeldung" />
  </>
);

export const PrivateRoutes = (
  <>
    <Redirect from="/" to="/Werkzeuguebersicht" />
    <Route
      key="itemOverview"
      path="/Werkzeuguebersicht"
      component={ItemOverview}
      exact
    />
    <Route
      key="itemOverviewVehicleItems"
      path="/Werkzeuguebersicht/:vehicleId/Werkzeugbestand"
      component={VehicleItemView}
      exact
    />
    <RoleBasedRoute
      key="vehicles"
      requiredRole={RoleType.ADMIN}
      path="/Fahrzeuge"
      component={VehicleView}
      exact
    />
    <RoleBasedRoute
      key="vehicleDetails"
      requiredRole={RoleType.ADMIN}
      path="/Fahrzeuge/:vehicleId"
      component={VehicleDetailView}
      exact
    />
    <RoleBasedRoute
      key="vehicleItems"
      requiredRole={RoleType.ADMIN}
      path="/Fahrzeuge/:vehicleId/Werkzeugbestand"
      component={VehicleItemView}
      exact
    />
    <RoleBasedRoute
      key="employeeDetails"
      requiredRole={RoleType.ADMIN}
      path="/Mitarbeiter/:employeeId"
      component={UserDetailView}
      exact
    />
    <RoleBasedRoute
      key="employees"
      requiredRole={RoleType.ADMIN}
      path="/Mitarbeiter"
      component={UsersView}
      exact
    />
    <Route
      key="settings"
      path="/Einstellungen"
      component={SettingsView}
      exact
    />
    <Redirect
      key="otherRedirect"
      to={
        SessionStore.requirePasswordReset
          ? "/Einstellungen"
          : "/Werkzeuguebersicht"
      }
    />
  </>
);

const Routes: React.FC = () => {
  if (SessionStore.user) return PrivateRoutes;
  return PublicRoutes;
};

export default observer(Routes);
