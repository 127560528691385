import {
  BleClient,
  dataViewToHexString,
  ScanResult,
} from "@capacitor-community/bluetooth-le";
import { Capacitor } from "@capacitor/core";

type EddystoneIds = {
  namespace: string;
  instance: string;
};

export const EDDYSTONE_INSTANCE = "65E5B30967D1";

let timer: NodeJS.Timeout;
let scannedDevices: string[] = [];
let itemCount: number;
let onItemFound: () => void;
let onScanFinished: (scannedDevices: string[]) => void;

const startScanTimer = (
  onScanFinished: (scannedDevices: string[]) => void,
  timeout?: number
) => {
  console.log("startScanTimer", timeout);

  return setTimeout(async () => {
    await BleClient.stopLEScan();
    console.log("finished scan");
    console.log("found devices: ", scannedDevices);

    onScanFinished(scannedDevices);
    return scannedDevices;
  }, timeout || 5000);
};

const onScanResult = async (scanResult: ScanResult) => {
  console.log("onScanResult");

  const eddystoneIds = getEddystoneIds(scanResult);
  if (eddystoneIds && eddystoneIds.instance === EDDYSTONE_INSTANCE) {
    scannedDevices.push(eddystoneIds.namespace);
  }
  onItemFound();
  clearTimeout(timer);
  if (scannedDevices.length >= itemCount) {
    console.log(">= itemCount");

    timer = startScanTimer(onScanFinished, 3000);
    return;
  }
  timer = startScanTimer(onScanFinished);
};

export async function scan(
  maxItemCount: number,
  onItemFoundCallBack: () => void,
  onScanFinishedCallback: (scannedDevices: string[]) => void
): Promise<void> {
  itemCount = maxItemCount;
  onItemFound = onItemFoundCallBack;
  onScanFinished = onScanFinishedCallback;
  console.log("starting scan");
  try {
    scannedDevices = [];
    await BleClient.initialize();

    const isBluetoothEnabled = await BleClient.isEnabled();
    if (Capacitor.getPlatform() !== "ios" && !isBluetoothEnabled) {
      await BleClient.enable();
    }

    await BleClient.requestLEScan({}, onScanResult);

    timer = startScanTimer(onScanFinished);
  } catch (error) {
    console.error(error);
  }
}

const getEddystoneIds = (scanResult: ScanResult) => {
  if (!scanResult.serviceData || !scanResult.uuids) return;
  const serviceData = scanResult.serviceData[scanResult.uuids[0]];
  const eddystoneData = dataViewToHexString(serviceData);
  const eddystoneDataBytes = eddystoneData.split(" ");
  const namespaceBytes = eddystoneDataBytes.slice(2, 12);
  const namespace = namespaceBytes.join("").toUpperCase();
  const instanceBytes = eddystoneDataBytes.slice(12, 18);
  const instance = instanceBytes.join("").toUpperCase();
  return { namespace, instance } as EddystoneIds;
};
