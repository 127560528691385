import { IonList, isPlatform } from "@ionic/react";
import { observer } from "mobx-react";
import VehicleToolEntry from "../../components/listItems/VehicleToolEntry";
import ItemStore from "../../store/ItemStore";
import ScanStore from "../../store/ScanStore";
import VehicleStore from "../../store/VehicleStore";

const ListItemsByVehicle: React.FC = () => {
  const scans = ScanStore.latestScansVehicleMap;

  return (
    <IonList inset={!isPlatform("mobile")} id="list-item-by-vehicle">
      {VehicleStore.vehicles.map((vehicle) => {
        return (
          <VehicleToolEntry
            vehicle={vehicle}
            key={vehicle?.id}
            scan={scans.get(vehicle.id)}
            totalItems={ItemStore.itemsGroupedByVehicleId[vehicle.id] || []}
          />
        );
      })}
    </IonList>
  );
};

export default observer(ListItemsByVehicle);
