import { IonItem, IonLabel, IonIcon } from "@ionic/react";
import { car } from "ionicons/icons";
import { VehicleType } from "../../core/firebase/types/vehicle.types";
import VehicleStore from "../../store/VehicleStore";

type VehicleEntryProps = {
  vehicle: VehicleType;
  detail?: boolean;
};

const VehicleEntry: React.FC<VehicleEntryProps> = ({
  vehicle,
  detail = false,
}) => {
  return (
    <IonItem
      detail={detail}
      lines={detail ? "inset" : "none"}
      onClick={() => {
        if (!detail) return;
        VehicleStore.setCurrentVehicleId(vehicle.id);
      }}
      routerLink={detail ? `/Fahrzeuge/${vehicle.id}` : undefined}
    >
      <IonIcon slot="start" icon={car} />
      <IonLabel style={{ fontWeight: "bolder" }}>
        {vehicle.name}
        <p>{vehicle.licensePlate}</p>
      </IonLabel>
      {/* <IonBadge slot="end">{vehicle.status}</IonBadge> */}
    </IonItem>
  );
};

export default VehicleEntry;
