import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import { Capacitor } from "@capacitor/core";

export const dev = {
  apiKey: "AIzaSyAcyHvCuLuEiLud_UsHLtu_7uFoGIec4A4",
  authDomain: "bitsecurity-69122.firebaseapp.com",
  databaseURL: "https://bitsecurity-69122.firebaseio.com",
  projectId: "bitsecurity-69122",
  storageBucket: "bitsecurity-69122.appspot.com",
  messagingSenderId: "365839940663",
  appId: "1:365839940663:web:2d0878bbbb41bb3f7253b2",
  measurementId: "G-9XJHSJRH8W",
};

const production = {
  apiKey: "AIzaSyAueihIIK4bc4k9i24IpQxo3ckNlKc7puE",
  authDomain: "tracktopus-prod.firebaseapp.com",
  projectId: "tracktopus-prod",
  storageBucket: "tracktopus-prod.appspot.com",
  messagingSenderId: "633226883096",
  appId: "1:633226883096:web:3db67955e9de218e7fd36d",
  measurementId: "G-WDYRMW4RXC",
};

const config = process.env.REACT_APP_STAGE === "production" ? production : dev;

const DEFAULT_REGION = "europe-west1";

const app = initializeApp(config);

if (Capacitor.isNativePlatform()) {
  initializeAuth(app, {
    persistence: indexedDBLocalPersistence,
  });
}

export const auth = getAuth();
export const functions = getFunctions(app, DEFAULT_REGION);

export const db = getFirestore();

export default app;
