import { Unsubscribe } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { action, makeObservable, observable } from "mobx";
import {
  addScan,
  subscribeScansByCompany,
  subscribeScansByVehicleIds,
} from "../core/firebase/collections/scanResultCollection";
import { Scan } from "../core/firebase/types/scan.types";
import SessionStore from "./SessionStore";
import VehicleStore from "./VehicleStore";

class ScanStore {
  latestScans: Scan[] = [];
  unsubscribe?: Unsubscribe;

  constructor() {
    makeObservable(this, {
      latestScans: observable,
      subscribeCompanyScans: action,
      subscribeVehicleScans: action,
      onNewSnapshot: action,
      resetStore: action,
    });
  }

  get foundIds() {
    return this.latestScans.flatMap((scan) => scan.foundIds);
  }

  get latestScansVehicleMap() {
    const latestScanByVehicle = new Map<string, Scan>();
    this.latestScans.forEach((scan) =>
      latestScanByVehicle.set(scan.vehicleId, scan)
    );
    return latestScanByVehicle;
  }

  get currentVehicleLatestScan() {
    return this.latestScansVehicleMap.get(VehicleStore.currentVehicleId!);
  }

  onNewSnapshot = (scanResults: Scan[]) => {
    this.latestScans = scanResults;
  };

  subscribeCompanyScans = () => {
    const unsubscribe = subscribeScansByCompany(this.onNewSnapshot);
    this.unsubscribe = unsubscribe;
  };

  subscribeVehicleScans = (vehicleIds: string[]) => {
    if (this.unsubscribe) this.unsubscribe();
    this.resetStore();

    if (!vehicleIds || !vehicleIds.length) return;
    const unsubscribe = subscribeScansByVehicleIds(
      vehicleIds,
      this.onNewSnapshot
    );
    this.unsubscribe = unsubscribe;
  };

  stopListeningForScanResults = () => {
    this.unsubscribe && this.unsubscribe();
    this.resetStore();
  };

  addScan = (foundIds: string[]) => {
    if (!SessionStore.userId) throw Error("user not found");
    if (!VehicleStore.currentVehicleId) throw Error("vehicle not found");

    const scan: Scan = {
      companyId: SessionStore.companyId,
      userId: SessionStore.userId,
      vehicleId: VehicleStore.currentVehicleId,
      latest: true,
      foundIds,
      timestamp: Timestamp.fromDate(new Date()),
    };
    return addScan(scan);
  };

  resetStore = () => {
    this.latestScans = [];
    this.unsubscribe = undefined;
  };
}

export default new ScanStore();
