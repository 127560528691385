import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import LoaderOverlay from "../../components/LoaderOverlay";
import {
  resetPasswordFirebase,
  signInWithMail,
} from "../../core/firebase/auth";

const LoginScreen: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [failedLogin, setFailedLogin] = useState(false);
  const history = useHistory();

  const loginUser = async () => {
    if (!email || !password) {
      loginIncomplete();
      return;
    }
    setIsLoading(true);
    await signInWithMail(email, password, loginSuccessful, loginFailed);
    setIsLoading(false);
  };

  const loginSuccessful = () => {
    history.replace("/Werkzeuguebersicht");
  };

  const loginIncomplete = () =>
    presentToast({
      message: "Bitte gib deine E-Mail und dein Passwort ein.",
      duration: 3500,
      color: "danger",
    });

  const loginFailed = () => {
    setFailedLogin(true);
    presentToast({
      message: "Login fehlgeschlagen - bitte prüfe deine Eingaben",
      duration: 3500,
      color: "danger",
    });
  };

  const resetPasswordAlert = () => {
    presentAlert({
      header: "Passwort zurücksetzen",
      message: `Möchten Sie eine Mail zum Zurücksetzen des Passworts zu ${email} anfordern?`,
      buttons: [
        { text: "Zurücksetzen", handler: () => resetPassword() },
        "Abbrechen",
      ],
    });
  };

  const resetPassword = async () => {
    if (!email) return;
    await resetPasswordFirebase(email);
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (e.key === "Enter") loginUser();
  };

  return (
    <IonPage>
      <IonContent className="" color="tertiary">
        <div className="ion-text-center ion-padding login-container">
          <LoaderOverlay isOpen={isLoading} message="Einloggen.." />
          <div className="body">
            <div className="cta">
              <div className="cta__content">
                <img
                  src="assets/icon/Icon_Name_Slogan_vertical_withBackground.png"
                  alt="tracktopus logo"
                />
              </div>
            </div>
            <div className="form">
              <div className="form__content">
                <div className="form__content__header">
                  <h1 className="form__content__heading">Anmelden</h1>
                  <div className="form__content__description">
                    Melde dich hier mit deinem bei Tracktopus registrierten
                    Account an.
                  </div>
                </div>
                <div className="form__field">
                  <IonItem id="ion-item-mail">
                    <IonLabel position="stacked" color="primary">
                      E-Mail
                    </IonLabel>
                    <IonInput
                      placeholder="E-Mail"
                      required
                      onIonChange={(e: any) => setEmail(e.target.value)}
                    />
                  </IonItem>
                  <IonItem id="ion-item-password" lines="none">
                    <IonLabel position="stacked" color="primary">
                      Passwort
                    </IonLabel>
                    <IonInput
                      required
                      type="password"
                      placeholder="Passwort"
                      onIonChange={(e: any) => setPassword(e.target.value)}
                      onKeyDown={onPressEnter}
                    />
                  </IonItem>
                </div>
                <div className="form__field form__submit">
                  <IonButton
                    id="login_button"
                    type="submit"
                    expand="block"
                    onClick={loginUser}
                  >
                    Einloggen
                  </IonButton>
                  {failedLogin && (
                    <a onClick={resetPasswordAlert}>Passwort vergessen?</a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginScreen;
