import { IonContent, IonList, IonMenu } from "@ionic/react";
import { getPages } from "../AppPages";
import { MenuItem } from "./MenuItem";
import UserLoginInfo from "../UserLoginInfo";

const SidebarMenu: React.FC = () => {
  return (
    <IonMenu className="menu" contentId="main" type="overlay">
      <IonContent color="light">
        <div id="banner-container">
          <img
            id="banner"
            src="assets/icon/Icon_Name_horizontal_noBackground.png"
            alt="tracktopus logo"
          />
        </div>
        <UserLoginInfo />
        <IonList>
          {getPages().map((appPage) => (
            <MenuItem key={appPage.title} appPage={appPage} />
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default SidebarMenu;
