import {
  beforeAuthStateChanged,
  onAuthStateChanged,
  User,
} from "firebase/auth";
import { action, makeObservable, observable } from "mobx";
import { auth } from "../core/firebase";
import { getCurrentUser } from "../core/firebase/collections/userCollection";
import { RoleType, UserType } from "../core/firebase/types/user.types";
import { SubscriptionHandler } from "./SubscriptionHandler";

class SessionStore {
  firebaseUser: User | null = null;
  user?: UserType;
  requirePasswordReset = false;
  loading: boolean = false;

  constructor() {
    makeObservable(this, {
      firebaseUser: observable,
      loading: observable,
      user: observable,
      requirePasswordReset: observable,
      updatePasswordResetClaim: action,
      updateUser: action,
    });

    beforeAuthStateChanged(auth, async (userAuth) => {
      if (!userAuth) {
        SubscriptionHandler.unsubscribeCollections();
        return;
      }
      const tokenResult = await userAuth.getIdTokenResult();
      const claims = tokenResult.claims;

      if (!claims.admin && !claims.employee && !claims.superuser)
        throw Error("User is not authorized to use the app");

      this.updatePasswordResetClaim(claims.requirePasswordReset);
    });

    onAuthStateChanged(auth, async (userAuth) => {
      console.log("user changed", userAuth?.email);
      this.updateUser(userAuth);
    });
  }

  updateUser = async (fbUser: User | null = null) => {
    this.loading = true;
    const user = await getCurrentUser(fbUser?.uid);
    this.firebaseUser = fbUser;
    this.user = user;
    if (fbUser) SubscriptionHandler.subscribeCollections();
    this.loading = false;
  };

  updatePasswordResetClaim = (requirePasswordReset: boolean) => {
    this.requirePasswordReset = requirePasswordReset;
  };

  get isAdmin() {
    if (!this.user) throw Error("User not found");
    return this.user.roles.includes(RoleType.ADMIN);
  }

  get isEmployee() {
    if (!this.user) throw Error("User not found");
    return this.user.roles.includes(RoleType.EMPLOYEE);
  }

  get companyId(): string {
    if (!this.user) throw Error("User not found");
    return this.user.companyId;
  }

  get userId() {
    return this.firebaseUser?.uid;
  }
}

export default new SessionStore();
