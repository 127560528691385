import { doc, getDoc, orderBy, query, setDoc, where } from "firebase/firestore";
import { CollectionName } from "../types/collections.types";
import { UserType } from "../types/user.types";
import { createCollection, subscribeCollection } from "./collectionService";

const userCollection = createCollection<UserType>(CollectionName.USERS);

export const getCurrentUser = async (userId?: string) => {
  if (!userId) return;
  console.log("getting current user");
  const userRef = doc(userCollection, userId);
  const userDoc = await getDoc(userRef);

  return { ...userDoc.data(), id: userDoc.id } as UserType;
};

export const subscribeUsersByCompany = (
  companyId: string,
  onNewSnapshot: (users: UserType[]) => void
) => {
  console.log("fetching all users from company");
  const userQuery = query(
    userCollection,
    where("companyId", "==", companyId),
    orderBy("firstName")
  );
  return subscribeCollection<UserType>(userQuery, onNewSnapshot);
};

export const addNewUser = async (user: UserType) => {
  const userRef = doc(userCollection, user.id);
  await setDoc(userRef, user);
};

export default userCollection;
