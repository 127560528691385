export type AddUserFormType = {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
};

export const nameValidation = {
  required: true,
  maxLength: 30,
  minLength: 2,
};

export const mailValidation = {
  required: true,
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "invalid email",
  },
};

export const passwordValidation = {
  required: true,
  minLength: 8,
};
