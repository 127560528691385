import { GeoPoint, Timestamp } from "firebase/firestore";

export enum ScanStatus {
  CONNECTED = "connected",
  UNCONNECTED = "unconnected",
  UNKNOWN = "unknown",
}

export type Scan = {
  latest?: boolean;
  timestamp: Timestamp;
  location?: GeoPoint;
  userId: string;
  vehicleId: string;
  companyId: string;
  foundIds: string[];
};
