import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeSharp } from "ionicons/icons";

type ModalProps = {
  title: string;
  isOpen: boolean;
  closeModal: () => void;
  noCloseButton?: boolean;
  leftHeaderButtons?: JSX.Element;
  rightHeaderButtons?: JSX.Element;
  headerElements?: JSX.Element;
  footerElements?: JSX.Element;
};

const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  closeModal,
  leftHeaderButtons,
  rightHeaderButtons,
  noCloseButton,
  headerElements,
  children,
  footerElements,
}) => {
  return (
    <IonModal
      className="modal"
      isOpen={isOpen}
      mode="ios"
      onDidDismiss={closeModal}
      canDismiss={!noCloseButton}
    >
      <IonHeader>
        <IonToolbar color="primary">
          {leftHeaderButtons && (
            <IonButtons slot="start">{leftHeaderButtons}</IonButtons>
          )}
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton slot="end" onClick={closeModal}>
              {rightHeaderButtons}
              {!noCloseButton && <IonIcon slot="icon-only" icon={closeSharp} />}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {headerElements}
      </IonHeader>
      <IonContent className="ion-padding">{children}</IonContent>
      {footerElements && (
        <IonFooter color="primary">{footerElements}</IonFooter>
      )}
    </IonModal>
  );
};

export default Modal;
