import { carSharp, hammerSharp, people, settingsSharp } from "ionicons/icons";
import { RoleType } from "../../core/firebase/types/user.types";
import SessionStore from "../../store/SessionStore";

export interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  role?: RoleType;
}

const itemOverviewPage: AppPage = {
  title: "Werkzeuge",
  url: "/Werkzeuguebersicht",
  iosIcon: hammerSharp,
  mdIcon: hammerSharp,
};

const settingsPage: AppPage = {
  title: "Einstellungen",
  url: "/Einstellungen",
  iosIcon: settingsSharp,
  mdIcon: settingsSharp,
};

const vehiclePage: AppPage = {
  title: "Fahrzeuge",
  url: "/Fahrzeuge",
  iosIcon: carSharp,
  mdIcon: carSharp,
  role: RoleType.ADMIN,
};

const employeePage: AppPage = {
  title: "Mitarbeiter",
  url: "/Mitarbeiter",
  iosIcon: people,
  mdIcon: people,
  role: RoleType.ADMIN,
};

const adminPages: AppPage[] = [
  itemOverviewPage,
  vehiclePage,
  employeePage,
  settingsPage,
];

const employeePages: AppPage[] = [itemOverviewPage, settingsPage];

export const getPages = () => {
  if (!SessionStore.user) return [];
  if (SessionStore.isAdmin) return adminPages;
  return employeePages;
};
