import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { UserType } from "../../core/firebase/types/user.types";
import UserStore from "../../store/UserStore";
import UsersViewAdd from "./AddUser/UsersViewAdd";
import UserEntry from "./UserEntry";

const UsersView: React.FC = () => {
  const [usersToShow, setUsersToShow] = useState<UserType[]>([]);
  const [searchString, setSearchString] = useState<string>();

  const getSearchResult = () => {
    if (!searchString) return UserStore.users;

    const searchTerms = searchString.toLowerCase().split(" ");

    return UserStore.users.filter((user) => {
      return searchTerms.every(
        (searchTerm) =>
          user.firstName?.toLowerCase().includes(searchTerm) ||
          user.lastName?.toLowerCase().includes(searchTerm) ||
          user.email.toLowerCase().includes(searchTerm)
      );
    });
  };

  useEffect(() => {
    setUsersToShow(getSearchResult());
  }, [UserStore.users, searchString]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Mitarbeiter</IonTitle>
          <UsersViewAdd />
        </IonToolbar>
        <IonToolbar color="primary">
          <IonSearchbar
            placeholder="Mitarbeiter suchen"
            debounce={500}
            onIonChange={(e) => setSearchString(e.detail.value)}
            color="light"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <IonList inset={!isPlatform("mobile")} color="light">
          {usersToShow.map((user) => (
            <UserEntry key={user.id} user={user} detail />
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default observer(UsersView);
