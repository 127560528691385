import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { observer } from "mobx-react";
import Routes from "../../../router/Routes";
import SessionStore from "../../../store/SessionStore";
import SidebarMenu from "./SidebarMenu";

const Menu: React.FC = () => {
  return (
    <IonSplitPane contentId="main">
      {!!SessionStore.user && <SidebarMenu />}
      <IonRouterOutlet id="main">
        <Routes />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default observer(Menu);
