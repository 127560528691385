import React from "react";
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
  IonContent,
} from "@ionic/react";
import VehicleTableView from "./vehicleTableView/VehicleTableView";

const VehicleView: React.FC = () => {
  return (
    <IonPage className="vehicleview-container">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Fahrzeuge</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <VehicleTableView />
      </IonContent>
    </IonPage>
  );
};

export default VehicleView;
