import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import LoaderOverlay from "../../components/LoaderOverlay";
import Modal from "../../components/modal/Modal";
import { updatePasswordFirebase } from "../../core/firebase/auth";
import { removePwResetClaim } from "../../core/firebase/functionCallables/userCallables";
import SessionStore from "../../store/SessionStore";
import { passwordValidation } from "../usersView/AddUser/InputValidators";
type ModalChangePasswordProps = {
  closeModal: () => void;
  isOpen: boolean;
};

const PasswordModal: React.FC<ModalChangePasswordProps> = ({
  isOpen,
  closeModal,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<PasswordFormType>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const [presentToast] = useIonToast();
  const user = SessionStore.firebaseUser;
  const [isLoading, setIsLoading] = useState(false);
  type PasswordFormType = {
    old_password: string;
    new_password: string;
    new_password_rep: string;
  };

  const changePassword = async (data: PasswordFormType) => {
    if (data.new_password != data.new_password_rep) {
      setError("new_password_rep", { type: "focus" }, { shouldFocus: true });
      return;
    }
    if (!user) return;
    setIsLoading(true);
    const status = await updatePasswordFirebase(
      user,
      data.old_password,
      data.new_password
    );

    if (status == "auth/password-updated") {
      passwordChanged();
    } else if (status == "auth/wrong-password") {
      errorToast(
        "Änderung fehlgeschlagen - bitte überprüfen Sie Ihr aktuelles Passwort"
      );
    } else {
      errorToast(
        "Änderung fehlgeschlagen - bitte versuchen Sie es später erneut"
      );
    }
    setIsLoading(false);
  };

  const passwordChanged = async () => {
    presentToast({
      message: `Passwort erfolgreich geändert`,
      duration: 2000,
      color: "primary",
    });
    if (!user) return;
    const pwResetStatus = await removePwResetClaim(user.uid);
    if (pwResetStatus == "pwresetclaimremoved")
      SessionStore.updatePasswordResetClaim(false);

    closeModal();
  };

  const errorToast = (text: string) =>
    presentToast({
      message: text,
      duration: 3500,
      color: "danger",
    });

  const submitButton = (
    <IonToolbar color="light">
      <IonButton onClick={handleSubmit(changePassword)} slot="end">
        <IonLabel>Speichern</IonLabel>
      </IonButton>
    </IonToolbar>
  );

  return (
    <Modal
      title="Passwort"
      isOpen={isOpen}
      closeModal={closeModal}
      noCloseButton={SessionStore.requirePasswordReset}
      footerElements={submitButton}
    >
      <IonList>
        <IonItem>
          <IonLabel position="stacked">Aktuelles Passwort</IonLabel>
          <IonInput
            type="password"
            {...register("old_password", passwordValidation)}
          />
          {errors.old_password && (
            <IonNote slot="helper">
              Bitte geben Sie ihr aktuelles Passwort ein
            </IonNote>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Neues Passwort</IonLabel>
          <IonInput
            type="password"
            {...register("new_password", passwordValidation)}
          />
          {errors.new_password && (
            <IonNote slot="helper">
              Bitte geben Sie ein Passwort mit mindestens{" "}
              {passwordValidation.minLength} Zeichen ein
            </IonNote>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Passwort wiederholen</IonLabel>
          <IonInput
            type="password"
            {...register("new_password_rep", passwordValidation)}
          />
          {errors.new_password_rep && (
            <IonNote slot="helper">Passwort muss übereinstimmen</IonNote>
          )}
        </IonItem>

        <LoaderOverlay
          isOpen={isLoading}
          message="Passwort wird festgelegt.."
        />
      </IonList>
    </Modal>
  );
};
export default PasswordModal;
