import { IonButton, IonButtons, IonIcon } from "@ionic/react";
import { addSharp } from "ionicons/icons";
import { useState } from "react";
import ModalAddUser from "./ModalAddUser";

const UsersViewAdd = () => {
  const [modalAddUserOpen, setModalAddUserOpen] = useState(false);

  return (
    <>
      <IonButtons slot="end">
        <IonButton onClick={() => setModalAddUserOpen(true)}>
          <IonIcon slot="icon-only" icon={addSharp} />
        </IonButton>
      </IonButtons>
      <ModalAddUser
        closeModal={() => setModalAddUserOpen(false)}
        isOpen={modalAddUserOpen}
      />
    </>
  );
};

export default UsersViewAdd;
