import { IonLoading } from "@ionic/react";

interface LoaderOverlayProps {
  isOpen: boolean;
  message?: string;
}

const LoaderOverlay = (props: LoaderOverlayProps) => {
  return (
    <IonLoading
      spinner="bubbles"
      mode="ios"
      isOpen={props.isOpen}
      message={props.message}
      showBackdrop={false}
    />
  );
};

export default LoaderOverlay;
