import {
  EmailAuthProvider,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  User,
} from "firebase/auth";
import { auth } from ".";
import { PasswortChangeStatus } from "./types/auth.types";

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: "select_account" });

//  For MVP users cannot sign up themselves
//  temporarily commented out for later use

// export const signUpWithMail = (
//   email: string,
//   password: string,
//   onSuccess?: () => void,
//   onError?: () => void
// ) => {
//   createUserWithEmailAndPassword(auth, email, password)
//     .then((userCredential) => {
//       const newUser: UserType = {
//         id: userCredential.user.uid,
//         email,
//         roles: [RoleType.EMPLOYEE],
//         firstName: userCredential.user.displayName || "SomeUser",
//         lastName: userCredential.user.displayName || "SomeUser",
//         companyId: "Company A",
//         status: "unknown",
//       };
//       addNewUser(newUser);
//       console.log("successfully created new user");
//       sendEmailVerification(userCredential.user);
//       onSuccess && onSuccess();
//       createBrowserHistory().push("/");
//     })
//     .catch((err) => {
//       onError && onError();
//       console.log(err);
//     });
// };

// export const signUpWithGoogle = async () => {
//   try {
//     const userCredential = await signInWithPopup(auth, googleAuthProvider);
//     // if (!userCredential.user.isNewUser) {
//     //   createBrowserHistory().push("/");
//     // } else {
//     const { user } = userCredential;
//     const userData: UserType = {
//       id: user.uid,
//       roles: [RoleType.EMPLOYEE],
//       firstName: userCredential.user.displayName || "SomeUser",
//       lastName: userCredential.user.displayName || "SomeUser",
//       companyId: "Company A",
//       status: "unknown",
//     };
//     await addNewUser(userData);
//     console.log("successfully signed up");
//     createBrowserHistory().push("/");
//     // }
//   } catch (err) {
//     // TODO: Error Feedback
//     console.log(err);
//   }
// };

export const signInWithMail = async (
  email: string,
  password: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  console.log("signing in with Email and password");

  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      console.log("signed in successfully", userCredential.user.uid);
      onSuccess && onSuccess();
    })
    .catch((err) => {
      console.log(err);
      onError && onError();
    });
};

export const resetPasswordFirebase = (
  email: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log("sent password reset mail");
      onSuccess && onSuccess();
    })
    .catch((e) => {
      console.log(e);
      onError && onError();
    });
};

export const updatePasswordFirebase = async (
  user: User,
  oldPassword: string,
  newPassword: string
): Promise<PasswortChangeStatus> => {
  try {
    // reauthenticate may be required if signin was not recently
    await reauthenticate(user, oldPassword);
    await updatePassword(user, newPassword);
    return "auth/password-updated";
  } catch (err) {
    console.error(err);
    const { _, code } = JSON.parse(JSON.stringify(err));
    return code;
  }
};
const reauthenticate = (user: User, oldPassword: string) => {
  if (!user.email) throw new Error("auth/error");
  const cred = EmailAuthProvider.credential(user.email, oldPassword);
  return reauthenticateWithCredential(user, cred);
};

export const logout = () => {
  signOut(auth)
    .then(() => window.location.reload())
    .catch((err) => console.error("signout failed:", err));
};
