import { doc, query, updateDoc, where } from "firebase/firestore";
import SessionStore from "../../../store/SessionStore";
import { CollectionName } from "../types/collections.types";
import { VehicleType } from "../types/vehicle.types";
import { createCollection, subscribeCollection } from "./collectionService";

const vehicleCollection = createCollection<VehicleType>(
  CollectionName.VEHICLES
);

export const subscribeVehiclesByCompany = (
  onNewSnapshot: (vehicles: VehicleType[]) => void
) => {
  console.log("fetching all vehicles from company");
  const vehicleQuery = query(
    vehicleCollection,
    where("companyId", "==", SessionStore.companyId)
  );
  return subscribeCollection<VehicleType>(vehicleQuery, onNewSnapshot);
};

export const subscribeVehiclesByUser = (
  userId: string,
  onNewSnapshot: (vehicles: VehicleType[]) => void
) => {
  console.log("fetching all vehicles assigned to user");
  const vehicleQuery = query(
    vehicleCollection,
    where("companyId", "==", SessionStore.companyId),
    where("responsibleUsers", "array-contains", userId)
  );
  return subscribeCollection<VehicleType>(vehicleQuery, onNewSnapshot);
};

export const assignEmployeesToVehicle = (
  vehicleId: string,
  responsibleUsers: string[]
) => {
  const vehicleDoc = doc(vehicleCollection, vehicleId);
  return updateDoc(vehicleDoc, { responsibleUsers });
};

export default vehicleCollection;
