import ItemStore from "./ItemStore";
import ScanStore from "./ScanStore";
import SessionStore from "./SessionStore";
import UserStore from "./UserStore";
import VehicleStore from "./VehicleStore";

const subscribeCollections = () => {
  console.log("subscribing to collections");
  VehicleStore.subscribeVehicles();
  UserStore.subscribeUsers();
  if (SessionStore.isAdmin) {
    // ItemListStore.subscribeItemLists();
    ItemStore.subscribeCompanyItems();
    ScanStore.subscribeCompanyScans();
  }
};

const unsubscribeCollections = () => {
  console.log("unsubscribe from listeners");
  VehicleStore.stopListeningForVehicles();
  // ItemListStore.stopListeningForItemLists();
  ItemStore.stopListeningForItems();
  ScanStore.stopListeningForScanResults();
  UserStore.stopListeningForUsers();
};

export const SubscriptionHandler = {
  subscribeCollections,
  unsubscribeCollections,
};
