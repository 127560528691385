import { Unsubscribe } from "firebase/firestore";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  assignEmployeesToVehicle,
  subscribeVehiclesByCompany,
  subscribeVehiclesByUser,
} from "../core/firebase/collections/vehicleCollection";
import { VehicleType } from "../core/firebase/types/vehicle.types";
import ItemStore from "./ItemStore";
import ScanStore from "./ScanStore";
import SessionStore from "./SessionStore";

// type PrivateMembers =
//   | "currentVehicleId"
//   | "onNewVehicleSnapshot"
//   | "setUnsubscribe"
//   | "unsubscribeVehicles"
//   | "resetStore"
//   | "getVehicleIds";

class VehicleStore {
  vehicles: VehicleType[] = [];
  currentVehicleId?: string;
  unsubscribeVehicles?: Unsubscribe;

  constructor() {
    makeObservable(this, {
      vehicles: observable,
      currentVehicleId: observable,
      currentVehicle: computed,
      vehicleIds: computed,
      getVehicle: action,
      subscribeVehicles: action,
      setCurrentVehicleId: action,
      stopListeningForVehicles: action,
    });
  }

  get currentVehicle() {
    const currVehicle = this.vehicles.find(
      (vehicle) => vehicle.id === this.currentVehicleId
    );
    if (!currVehicle) return;
    return currVehicle;
  }

  get vehicleIds() {
    return this.getVehicleIds(this.vehicles).sort();
  }

  getVehicle = (vehicleId: string) => {
    return this.vehicles.find((vehicle) => vehicle.id === vehicleId);
  };

  onNewVehicleSnapshot = (vehicles: VehicleType[]) => {
    const newVehicleIds = this.getVehicleIds(vehicles).sort();
    if (!SessionStore.isAdmin && this.vehicleIds !== newVehicleIds) {
      ItemStore.subscribeVehicleItems(newVehicleIds);
      ScanStore.subscribeVehicleScans(newVehicleIds);
    }
    runInAction(() => {
      this.vehicles = vehicles;
    });
  };

  setUnsubscribe = (unsubscribe?: Unsubscribe) => {
    runInAction(() => {
      this.unsubscribeVehicles = unsubscribe;
    });
  };

  subscribeVehicles() {
    const user = SessionStore.user;
    if (!user) return;

    let unsubscribe;
    if (SessionStore.isAdmin) {
      unsubscribe = subscribeVehiclesByCompany(this.onNewVehicleSnapshot);
    } else if (SessionStore.isEmployee) {
      unsubscribe = subscribeVehiclesByUser(user.id, this.onNewVehicleSnapshot);
    } else {
      console.error("permission needed");
      return;
    }
    this.setUnsubscribe(unsubscribe);
  }

  setCurrentVehicleId = (vehicleId: string) => {
    runInAction(() => {
      this.currentVehicleId = vehicleId;
    });
  };

  assignEmployeesToVehicle = (employeeIds: string[]) => {
    if (!this.currentVehicleId) throw Error("Vehicle not found");
    return assignEmployeesToVehicle(this.currentVehicleId, employeeIds);
  };

  stopListeningForVehicles = () => {
    this.unsubscribeVehicles && this.unsubscribeVehicles();
    if (!SessionStore.isAdmin) {
      ItemStore.stopListeningForItems();
      ScanStore.stopListeningForScanResults();
    }
    this.resetStore();
  };

  private getVehicleIds = (vehicles: VehicleType[]) => {
    return vehicles.map((vehicle) => vehicle.id);
  };

  private resetStore = () => {
    runInAction(() => {
      this.vehicles = [];
      this.currentVehicleId = undefined;
      this.unsubscribeVehicles = undefined;
    });
  };
}

export default new VehicleStore();
