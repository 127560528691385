import LoaderOverlay from "../../components/LoaderOverlay";

type ItemScanLoaderProps = {
  itemCount: number;
  foundItems: number;
  isOpen: boolean;
};

const ItemScanLoader: React.FC<ItemScanLoaderProps> = ({
  itemCount,
  foundItems,
  isOpen,
}) => {
  return (
    <LoaderOverlay
      message={`Suche nach Geräten..`}
      //   message={`Suche nach Geräten (${foundItems}/${itemCount})`}
      isOpen={isOpen}
    />
  );
};

export default ItemScanLoader;
