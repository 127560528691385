import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { observer } from "mobx-react";
import { useState } from "react";
import ItemStore from "../../store/ItemStore";
import ItemViewFilter from "./ItemViewFilter";
import ListItemsByConnection from "./ListItemsByConnection";
import ListItemsByVehicle from "./ListItemsByVehicles";
import { ItemFilter } from "./types";

const ItemOverview: React.FC = () => {
  const [filter, setFilter] = useState<ItemFilter>("vehicle");

  const getContentByFilter = () => {
    if (!ItemStore.itemCount)
      return (
        <p className="ion-padding">
          <i>Keine Werkzeuge vorhanden</i>
        </p>
      );
    if (filter === "connection") return <ListItemsByConnection />;
    return <ListItemsByVehicle />;
  };

  return (
    <IonPage className="item-overview-container">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Werkzeuge</IonTitle>
          <IonButtons slot="end">
            <ItemViewFilter filter={filter} setFilter={setFilter} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">{getContentByFilter()}</IonContent>
    </IonPage>
  );
};

export default observer(ItemOverview);
