import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import LoaderOverlay from "../../../components/LoaderOverlay";
import {
  getNameByRole,
  UserType,
} from "../../../core/firebase/types/user.types";
import UserStore from "../../../store/UserStore";
import UserEntry from "../UserEntry";
import UserDelete from "./UserDelete";
import UserEdit from "./UserEdit";

const UserDetailView: React.FC = () => {
  const { employeeId } = useParams<{ employeeId: string }>();

  const [employee, setEmployee] = useState<UserType>();
  const [showLoader, setShowLoader] = useState(false);
  const [presentToast] = useIonToast();

  useEffect(() => {
    getEmployee();
  }, [UserStore.users]);

  const getEmployee = () => {
    const user = UserStore.getUser(employeeId);
    if (!user) return;
    setEmployee(user);
  };

  if (!employee)
    return <LoaderOverlay message="Mitarbeiter wird geladen.." isOpen={true} />;

  const errorToast = (action: string) =>
    presentToast({
      message: `${action} fehlgeschlagen - bitte später nochmal versuchen`,
      duration: 3500,
      color: "danger",
    });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text="Zurück" defaultHref="/Fahrzeuge" />
          </IonButtons>
          <UserEdit user={employee} onSuccess={getEmployee} />
          <UserDelete
            user={employee}
            errorToast={errorToast}
            setShowLoader={setShowLoader}
          />
          <IonTitle>Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <UserEntry user={employee} />

        <IonList>
          <IonItem color="light"></IonItem>
          <IonItem>
            <IonLabel>Vorname</IonLabel>
            <IonLabel slot="end">{employee.firstName}</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Nachname</IonLabel>
            <IonLabel slot="end">{employee.lastName}</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Rolle</IonLabel>
            <IonLabel slot="end">{getNameByRole(employee.roles[0])}</IonLabel>
          </IonItem>
        </IonList>
        <LoaderOverlay isOpen={showLoader} message="Einen Moment bitte.." />
      </IonContent>
    </IonPage>
  );
};

export default withRouter(observer(UserDetailView));
