import {
  collection,
  CollectionReference,
  DocumentData,
  getFirestore,
  onSnapshot,
  Query,
} from "firebase/firestore";

export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(getFirestore(), collectionName) as CollectionReference<T>;
};

export const subscribeCollection = <DataType>(
  query: Query<DataType>,
  onNewSnapshot: (snapshotData: DataType[]) => void
) => {
  const unsubscribe = onSnapshot(
    query,
    (querySnapshot) => {
      const newSnapshotData = querySnapshot.docs.map((doc) => {
        const newData = { ...doc.data(), id: doc.id };
        return newData;
      });
      onNewSnapshot(newSnapshotData);
    },
    (error) => console.error(error)
  );
  return unsubscribe;
};
